<template>
  <div id="about">
    <div class="about">
      <Top />
      <div class="about-title">
        关于我们 <br />
        <span>ABOUT US</span>
      </div>
    </div>
    <div class="jianjie">
      <div class="jianjie-title">
        <div class="jianjie-title-e">ABOUT ZHU\NG BA</div>
        <div class="jianjie-title-c">企业简介</div>
      </div>
      <p style="margin-top: 30px">
        广东凯洋国际物流供应链有限公司由东莞市工商、交通运输部门批准成立，东莞市物流行业协会会员单位。是一家专业从事国际海运、空运、东南亚陆运及仓储物流于一体的综合性国际货运代理公司，是经国家外经贸部批准成立的一级货运代理企业，同时具有交通部认证的无船承运人资格，本公司拥有一批专业的物流管理人才和专业的运输队伍，以严格、科学的管理手段，现代化的物流设施，为客户提供最优质的服务。 “我们不限于客户的满意，我们力求做到杰出”是我们的经营方针，也是对广大客户的承诺。
      </p>
      <p>
       广东凯洋国际物流供应链有限公司在亚洲多年的发展根基和其他亚洲、欧洲、澳洲，北美的战略合作伙伴，辐射亚洲、全欧洲重要港口，北美区，非洲区、澳洲全境…专业团队拥有一批长期从业经验的资深人员组成，融合国内外实效经验，为客户提供优质服务。依托良好合作船东，码头，报关行及自有集卡，仓储，为客户提供优质高效的整箱海运，空运，多式联运，拖车，报关，以及仓储和增值业务的一站式物流配送服务方案。
      </p>
      <p>
        我们在每一次航运业务中都亲力亲为，并以此为荣，货物将始终受到我们无微不至的照料。我们将从专业的角度为您推荐、提供最佳的路线及最好的运价，随时掌握国内外市场动态及国际货运信息，依据您的要求和货物情况为您提供最佳的方案.
      </p>
      <div style="text-align:left" class="wenhua">
    【以客为尊】<br>
      我们以热诚的态度，致力为客户提供超乎期望的服务；<br>
    【诚信及尊重】<br>
      我们以高度的诚信处理每项交易，赢取雇员与顾客的尊重和信任；<br>
    【齐心事成】<br>
      我们团结一致，与各业务伙伴协力提供最优质的服务；<br>
    【重视人才】<br>
      我们透过开明的沟通嘉许出色的员工、提供适当的培训让员工发展潜能来缔造一个愉快的工作环境；<br>
    【力臻完美】<br>
      我们不断改进，运用科学的管理、卓越的电子商务平台为客户服务，力求每一细节达致完美；<br>
    【安全至上】<br>
      我们抱着负责任的态度营运业务，确保货物的安全和员工的健康。
      </div>
      
      <div class="jianjie-img">
        <ul>
          <li class="bg1">
            <div class="bgtitle">
              <div class="bgtitle-t">企业文化</div>
              <div class="bgtitle-c">CORPCRATE MISSION</div>
              <div class="bgtitle-b">
                我们不限于客户的满意，我们力求做到杰出
              </div>
            </div>
          </li>
          <li class="bg2"><div class="bgtitle">
            <div class="bgtitle">
              <div class="bgtitle-t">企业愿景</div>
              <div class="bgtitle-c">CORPCRATE VISION</div>
              <div class="bgtitle-b">
                专注，创新，务实，以客户为中心
              </div>
            </div></div></li>
          <li class="bg3"><div class="bgtitle">
            <div class="bgtitle">
            <div class="bgtitle">
              <div class="bgtitle-t">企业理念</div>
              <div class="bgtitle-c">ENTERPRISE'S GOAL</div>
              <div class="bgtitle-b">
                优质，快速，价廉
              </div>
            </div></div></div></li>
          <li class="bg4"><div class="bgtitle">
            <div class="bgtitle">
              <div class="bgtitle-t">企业目标</div>
              <div class="bgtitle-c">ENTREPRENEURIAL SPIRIT</div>
              <div class="bgtitle-b">
                缔造世界一流物流企业
              </div>
            </div></div></li>
        </ul>
      </div>
    </div>
    <Partner />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Top from "../components/topnav.vue";
import Partner from "../components/partner.vue";

export default {
  components: {
    Footer,
    Top,
    Partner,
  },
};
</script>

<style lang="scss" scoped>
.wenhua{
      font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 28px;
    text-align: left;
    margin-top: 40px;
}
#about {
  width: 100%;
}
.about {
  width: 100%;
  // height: 500px;
  min-height: 500px;
  background-image: url(https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &-title {
    margin-top: 145px;
    font-size: 72px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    > span {
      color: #cccccc;
      font-size: 60px;
      margin-top: 17px;
      display: inline-block;
    }
  }
}
// 简介
.jianjie {
  width: 1200px;
  margin: 97px auto;
  text-align: center;
  &-title-e {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
  }
  &-title-c {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-top: 14px;
  }
  > p {
    text-indent: 30px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #444444;
    line-height: 28px;
    text-align: left;
    display: inline-block;
  }
  // 简介图
  &-img {
    width: 100%;
    margin-top: 80px;
    > ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > li {
        width: 590px;
        height: 540px;
        margin-top: 20px;
        text-align: center;
      }
      > .bg1 {
        background: no-repeat url(https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/q1.png);
        background-size: 100%;
      }
      > .bg2 {
        background: no-repeat url(https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/q2.png);
        background-size: 100%;
      }
      > .bg3 {
        background: no-repeat url(https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/q3.png);
        background-size: 100%;
      }
      > .bg4 {
        background: no-repeat url(https://gdkayon.oss-cn-shenzhen.aliyuncs.com/image/q4.png);
        background-size: 100%;
      }
    }
  }
}
.bgtitle {
  margin-top: 217px;
  &-t {
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  &-c {
    font-size: 18px;
    font-family: PingFang SC;
    color: #ffffff;
    margin-top: 28px;
  }
  &-b {
    width: 392px;
    margin: 20px auto;
       font-size: 18px;
    font-family: PingFang SC;
    color: #ffffff;
    line-height: 28px;
  }
}
</style>